import $ from 'jquery';

class Menu {
  constructor() {
    this.header = $('header');
    this.body = $('body');
    this.html = $('html');
    this.mainMenu = $('.menu-component');
    this.mainMenuContainer = $('.menu-component .container');
    this.burgerIcon = $('#burger-icon');
    this.subMenus = $('.menu-item-has-children');
    this.subMenuLink = $('.menu-item-has-children > a');
    this.targetElement = document.getElementById('menu-main-menu');
    this.menuContainer = $('.nav');
    this.mainMenuCon = $('#main-menu');
    this.menuItems = $('.menu-item');
    this.menuOpen = false;
    this.back = $('a[href="#back"]');
    this.currentDropdown;
    this.events();
  }

  //menu events
  events() {
    this.burgerIcon.on('click', this.mobileMenuToggleOpen.bind(this));
    this.subMenus.on('click', this.openCloseMenuController.bind(this));
    this.back.on('click', this.closeSection.bind(this));
    this.body.on('click', this.clickOffMenuToClose.bind(this));
    $(window).on('orientationchange', this.onDeviceOrientationChange.bind(this));
    this.menuItems.on('mouseover', this.showDropDown.bind(this));
    this.header.on('mouseover', this.hideDropDown.bind(this));
  }

  hideDropDown () {
    $('.show-dropdown').removeClass('show-dropdown');
  }

  showDropDown () {
    let parent = event.target.parentElement;
    let dD = $('.show-dropdown');

    if (parent.classList.contains('menu-item-has-children')) {
      dD.removeClass('show-dropdown');
      let submenu = $(parent).find('.sub-menu');
      submenu.addClass('show-dropdown');
      this.currentDropdown = dD;
      this.currentDropdown.on('mouseout', this.hideDropDown.bind(this));
    } else {
      dD.removeClass('show-dropdown');
    }
  }

  // toggle menu open/ close classes - can be used for animations
  mobileMenuToggleOpen() {
    if (this.mainMenu.hasClass('nav--open')) {
      this.mainMenu.removeClass('nav--open');
      this.mainMenu.addClass('nav--closed');
      this.burgerIcon.removeClass('burger-icon--open');
      this.body.removeClass('no-scroll');
      const scrollY = this.body.scrollTop();
      this.body.css ('position', '');
      this.body.css ('top', '');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      this.mainMenu.removeClass('nav--closed');
      this.mainMenu.addClass('nav--open');
      this.burgerIcon.addClass('burger-icon--open');
      this.body.addClass('no-scroll');
      this.body.css('position', 'fixed');
      this.body.css('top', window.scrollY + 'px');
    }
  }

  closeSection(e) {
    e.target.parentElement.parentElement.classList.remove('show');
    this.body.scrollTop(0);
  }

  // toggle open/ close sub menu's
  openCloseMenuController(event) {
    let el = event.target;
    if(el.classList.contains('menu-item')) {
      $(el).find( '> .sub-menu' ).addClass('show');
    } else {
      return true;
    }

    this.checkIfMenuOpen();

    return false;
  }

  checkIfMenuOpen() {
    //checks if any sub menu has an active class on it
    this.menuOpen = this.menuContainer.find('.active').length > 0;
  }

  // closes all existing sub menus
  closeSubMenus() {
    for (var i = 0; i < this.subMenus.length; i++) {
      if ($(this.subMenus[i]).hasClass('active')) {
        $(this.subMenus[i]).removeClass('active');
      }
    }
  }

  clickOffMenuToClose(event) {
    let $target = $(event.target);
    if (!$target.closest(this.menuContainer).length && this.menuOpen) {
      this.closeSubMenus();
    }
  }

  // close menu on device rotate to prevent issues
  onDeviceOrientationChange() {
    if (this.mainMenu.hasClass('nav--open')) {
      this.mainMenu.removeClass('nav--open');
      this.burgerIcon.removeClass('burger-icon--open');
      this.body.removeClass('no-scroll');
      const scrollY = this.body.scrollTop();
      this.body.css ('position', '');
      this.body.css ('top', '');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }
}

export default Menu;
