export default class Search {
  constructor() {
    this.searchBar = jQuery('#search-bar');
    this.searchBarMob = jQuery('#search-bar-mobile');
    this.searchBarNews = jQuery('#search-bar-news');
    this.loadingSpinner = jQuery('<div id="search-spin-loader" class="spinner-loader"></div>');
    this.loadingSpinnerMob = jQuery('<div id="search-spin-loader-mobile" class="spinner-loader"></div>');
    this.loadingSpinnerNews = jQuery('<div id="search-spin-loader-news" class="spinner-loader"></div>');
    this.resultsDiv = jQuery('#search-bar-results');
    this.resultsDivNews = jQuery('#search-bar-news-results');
    this.resultsDivMob = jQuery('#search-bar-results-mobile');
    this.searchField = jQuery('#search-bar-search');
    this.searchFieldMob = jQuery('#search-bar-search-mobile');
    this.searchFieldNews = jQuery('#search-bar-news-search');
    this.previousValue;
    this.typingTimer;
    this.events();
  }

  events() {
    this.searchField.off('keyup').on('keyup', this.typingLogic.bind(this));
    this.searchFieldMob.off('keyup').on('keyup', this.typingLogicMob.bind(this));
    this.searchFieldNews.off('keypress').on('keypress', this.typingLogicNews.bind(this));
  }

  typingLogicMob() {
    if (this.searchFieldMob.val() !== this.previousValue) {
      clearTimeout(this.typingTimer);

      if (this.searchFieldMob.val()) {

        if (!this.isSpinnerVisible) {
          this.searchBarMob.append(this.loadingSpinnerMob);
          this.isSpinnerVisible = true;
        }

        this.typingTimer = setTimeout(this.getResultsMob.bind(this), 750);

      } else {
        this.loadingSpinnerMob.remove();
        this.resultsDivMob.html('');
        this.isSpinnerVisible = false;
        this.searchFieldMob.removeClass('active');
        this.resultsDivMob.removeClass('active');
      }
    }

    this.previousValue = this.searchFieldMob.val();
  }

  getResultsMob() {
    // eslint-disable-next-line no-undef
    dataLayer.push({'search_term': this.searchFieldMob.val()});
    // if using custom API file, use /wp-json/finally/v1/search?term=
    jQuery.when(

      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/posts?search=' + this.searchFieldMob.val() + '&orderby=relevance'),
      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/pages?search=' + this.searchFieldMob.val() + '&orderby=relevance')

    ).then((posts, pages) => {
      var combinedResults = posts[0].concat(pages[0]);
      this.resultsDivMob.html(`

      ${combinedResults.length ? '' : '<a href="#">No results found</a>'}
    ${combinedResults.map(item => `
            <a href="${item.link}">${item.title.rendered}</a>
      `).join('')}
      `);
      this.resultsDivMob.append( '<p>No. of results ' + combinedResults.length + '</p>');
      this.loadingSpinnerMob.remove();
      this.searchFieldMob.addClass('active');
      this.resultsDivMob.addClass('active');
      this.isSpinnerVisible = false;
    }, () => {
      this.resultsDivMob.html('<a href="#">Unexpected error, please try again.</a>')
      this.loadingSpinnerMob.remove();
      this.searchFieldMob.addClass('active');
      this.resultsDivMob.addClass('active');
      this.isSpinnerVisible = false;
    });
  }

  typingLogic() {
    if (this.searchField.val() !== this.previousValue) {

      clearTimeout(this.typingTimer);

      if (this.searchField.val() !== '') {

        if (!this.isSpinnerVisible) {
          this.searchBar.append(this.loadingSpinner);
          this.isSpinnerVisible = true;
        }

        this.typingTimer = setTimeout(this.getResults.bind(this), 750);

      } else {
        this.loadingSpinner.remove();
        this.resultsDiv.html('');
        this.isSpinnerVisible = false;
        this.searchField.removeClass('active');
        this.resultsDiv.removeClass('active');
      }
    }

    this.previousValue = this.searchField.val();
  }

  getResults() {
    // eslint-disable-next-line no-undef
    dataLayer.push({'search_term': this.searchField.val()});

    jQuery.when(

      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/posts?search=' + this.searchField.val()),
      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/pages?search=' + this.searchField.val())

    ).then((posts, pages) => {
      var combinedResults = posts[0].concat(pages[0]);
      this.resultsDiv.html(`

      ${combinedResults.length ? '' : '<a href="#">No results found</a>'}
    ${combinedResults.map(item => `
            <a href="${item.link}">${item.title.rendered}</a>
      `).join('')}`);
      this.resultsDiv.append( '<p>No. of results ' + combinedResults.length + '</p>');
      this.loadingSpinner.remove();
      this.searchField.addClass('active');
      this.resultsDiv.addClass('active');
      this.isSpinnerVisible = false;
    }, () => {
      this.resultsDiv.html('<a href="#">Unexpected error, please try again.</a>')
      this.loadingSpinner.remove();
      this.searchField.addClass('active');
      this.resultsDiv.addClass('active');
      this.isSpinnerVisible = false;
    });
  }

  typingLogicNews() {
    if (this.searchFieldNews.val() !== this.previousValue) {
      clearTimeout(this.typingTimer);

      if (this.searchFieldNews.val()) {

        if (!this.isSpinnerVisible) {
          this.searchBarNews.append(this.loadingSpinnerNews);
          this.isSpinnerVisible = true;
        }

        this.typingTimer = setTimeout(this.getResultsNews.bind(this), 750);

      } else {
        this.loadingSpinnerNews.remove();
        this.resultsDivNews.html('');
        this.isSpinnerVisible = false;
        this.searchFieldNews.removeClass('active');
      }
    }

    this.previousValue = this.searchFieldNews.val();
  }

  getResultsNews() {
    // eslint-disable-next-line no-undef
    dataLayer.push({'search_term': this.searchFieldNews.val()});
    // if using custom API file, use /wp-json/finally/v1/search?term=
    jQuery.when(
      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/posts?search=' + this.searchFieldNews.val()),
      // eslint-disable-next-line no-undef
      jQuery.getJSON(finallyData.root_url + '/wp-json/wp/v2/pages?search=' + this.searchFieldNews.val())
    ).then((posts, pages) => {
      var combinedResults = posts[0].concat(pages[0]);

      this.resultsDivNews.html(`
      ${combinedResults.length ? '' : '<a href="#">No results found</a>'}
				${combinedResults.map(item => `
            <a href="${item.link}">${item.title.rendered}</a>
   		`).join('')}
      `);
      this.loadingSpinnerNews.remove();
      this.searchFieldNews.addClass('active');
      this.resultsDivNews.addClass('active');
      this.isSpinnerVisible = false;
    }, () => {
      this.resultsDivNews.html('<a href="#">Unexpected error, please try again.</a>');
      this.loadingSpinnerNews.remove();
      this.searchFieldNews.addClass('active');
      this.resultsDivNews.addClass('active');
      this.isSpinnerVisible = false;
    });
  }
}

jQuery(document).on('click', function (e) {
  if ($(e.target).is('.search-bar--results') === false) {
    $('.search-bar__search').removeClass('active');
    $('.search-bar--results').removeClass('active');
  }
});

// eslint-disable-next-line no-unused-vars
var search = new Search();
